import { useState } from "react";

import { Button, Input, Form, Label, Message } from "semantic-ui-react";
import axios from "axios";
import "./App.css";

function App() {
  const queryParameters = new URLSearchParams(window.location.search);

  const [Name, setName] = useState(queryParameters.get("Name") || "");
  const [Beschreibung, setBeschreibung] = useState(
    queryParameters.get("Beschreibung") || ""
  );
  const [Inventarnummer, setInventarnummer] = useState(
    queryParameters.get("Inventarnummer") || ""
  );
  const [confirmation, setConfirmation] = useState("");
  const date = new Date();
  const Datum =
    date.getDate() + "." + date.getMonth() + "." + date.getFullYear();

  const handleSubmit = (e) => {
    e.preventDefault();
    const postData = { Inventarnummer, Beschreibung, Name, Datum };
    axios
      .post(
        "https://sheet.best/api/sheets/cb5b06ba-051d-4020-8012-d3fb56cbdcf1",
        postData
      )
      .then((response) => {
        console.log(response);
        setConfirmation("Der Eintrag wurde gespeichert.")
      });
  };



  return (
    <div className="container">
      <header>
        <h1>Ausleihe</h1>
      </header>
      <Form className="form">
        <Form.Field>
          <label>Inventarnummer</label>
          <input
            placeholder="Inventarnummer"
            value={Inventarnummer}
            onChange={(event) => setInventarnummer(event.target.value)}
          />
        </Form.Field>
        <Form.Field>
          <label>Beschreibung</label>
          <Input
            placeholder="Beschreibung"
            value={Beschreibung}
            onChange={(event) => setBeschreibung(event.target.value)}
          />
        </Form.Field>
        <Form.Field>
          <label>Ausgeliehen von</label>
          <Input
            placeholder="Ausgeliehen von"
            value={Name}
            onChange={(event) => setName(event.target.value)}
          />
        </Form.Field>

        <Button type="submit" onClick={handleSubmit}>
          Ausleihen
        </Button>
        {confirmation ? (<Message color='green'>{confirmation}</Message>) : ''}
      </Form>
    </div>
  );
}

export default App;
